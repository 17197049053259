import cgvlogo from "../../assets/footer-icon-1.png";
import bhdlogo from "../../assets/footer-icon-2.png";
import galaxylogo from "../../assets/footer-icon-3.png";
import starlogo from "../../assets/footer-icon-4.png";
import lottelogo from "../../assets/footer-icon-5.png";
import megalogo from "../../assets/icon-footer-6.png";
import betalogo from "../../assets/icon-footer-7.jpg";
import ddclogo from "../../assets/icon-footer-8.png";
import touchlogo from "../../assets/icon-footer-9.png";
import cinemaxlogo from "../../assets/icon-footer-10.jpg";
import starlightlogo from "../../assets/icon-footer-11.png";
import dcinelogo from "../../assets/icon-footer-12.png";
import zalologo from "../../assets/icon-footer-13.png";
import payoologo from "../../assets/icon-footer-14.png";
import vietcombanklogo from "../../assets/icon-footer-15.png";
import agribanklogo from "../../assets/icon-footer-16.png";
import viettinbanklogo from "../../assets/icon-footer-17.png";
import ivblogo from "../../assets/icon-footer-18.png";
import bachamlogo from "../../assets/icon-footer-19.png";
import labanlogo from "../../assets/icon-footer-20.jpg";

export const mangHinh = [
  {
    tenHinh: "cgvlogo",
    link: "https://www.cgv.vn/",
    linkHing: cgvlogo,
  },
  {
    tenHinh: "bhdlogo",
    link: "https://www.bhdstar.vn/",
    linkHing: bhdlogo,
  },
  {
    tenHinh: "galaxylogo",
    link: "https://www.galaxycine.vn/",
    linkHing: galaxylogo,
  },
  {
    tenHinh: "starlogo",
    link: "http://cinestar.com.vn/",
    linkHing: starlogo,
  },
  {
    tenHinh: "lottelogo",
    link: "http://lottecinemavn.com/LCHS/index.aspx",
    linkHing: lottelogo,
  },
  {
    tenHinh: "megalogo",
    link: "https://www.megagscinemas.vn/",
    linkHing: megalogo,
  },
  {
    tenHinh: "betalogo",
    link: "https://www.betacinemas.vn/home.htm",
    linkHing: betalogo,
  },
  {
    tenHinh: "ddclogo",
    link: "http://ddcinema.vn/",
    linkHing: ddclogo,
  },
  {
    tenHinh: "touchlogo",
    link: "https://touchcinema.com/",
    linkHing: touchlogo,
  },
  {
    tenHinh: "cinemaxlogo",
    link: "https://cinemaxvn.com/",
    linkHing: cinemaxlogo,
  },
  {
    tenHinh: "starlightlogo",
    link: "https://starlight.vn/",
    linkHing: starlightlogo,
  },
  {
    tenHinh: "dcinelogo",
    link: "https://www.dcine.vn/",
    linkHing: dcinelogo,
  },
  {
    tenHinh: "zalologo",
    link: "https://zalopay.vn/",
    linkHing: zalologo,
  },
  {
    tenHinh: "payoologo",
    link: "https://www.payoo.vn/",
    linkHing: payoologo,
  },
  {
    tenHinh: "vietcombanklogo",
    link: "https://portal.vietcombank.com.vn/Pages/Home.aspx",
    linkHing: vietcombanklogo,
  },
  {
    tenHinh: "agribanklogo",
    link: "https://www.payoo.vn/",
    linkHing: agribanklogo,
  },
  {
    tenHinh: "viettinbanklogo",
    link: "https://www.vietinbank.vn/web/home/vn/index.html",
    linkHing: viettinbanklogo,
  },
  {
    tenHinh: "ivblogo",
    link: "https://www.indovinabank.com.vn/",
    linkHing: ivblogo,
  },
  {
    tenHinh: "bachamlogo",
    link: "https://webv3.123go.vn/",
    linkHing: bachamlogo,
  },
  {
    tenHinh: "labanlogo",
    link: "https://laban.vn/",
    linkHing: labanlogo,
  },
];
